<template>
  <div>
    <div class="search-container">
      <el-row>
        <el-col :span="16">
          <el-form label-width="80px" :model="form">
            <el-row>
              <el-col :span="8">
                <el-form-item label="设备ID">
                  <el-input
                    v-model="form.deviceSn"
                    clearable
                    placeholder="请输入设备ID"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="闸机名称">
                  <el-input
                    v-model="form.name"
                    clearable
                    placeholder="请输入闸机名称"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="所属场馆">
                  <el-input
                    v-model="form.venueName"
                    clearable
                    placeholder="请输入所属场馆"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
        <el-col :span="8" style="text-align: right; padding-right: 50px">
          <el-button @click="cleanForm" class="clean-button">清空</el-button>
          <el-button @click="searchEvent" class="search-button">查询</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight - 50"
        style="width: 100%"
        border
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" align="center" width="55">
        </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="55">
        </el-table-column>
        <el-table-column
          prop="status"
          align="center"
          label="状态"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          prop="deviceSn"
          align="center"
          label="设备ID"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          align="center"
          label="闸机名称"
          min-width="200"
        >
        </el-table-column>
        <el-table-column
          prop="venueName"
          align="center"
          label="所属场馆"
          min-width="200"
        >
        </el-table-column>
        <el-table-column
          prop="passageType"
          align="center"
          label="进出控制"
          min-width="150"
          :formatter="passageTypeFormatter"
        >
        </el-table-column>
        <el-table-column
          prop="updateTime"
          align="center"
          label="最后通讯时间"
          min-width="200"
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="operation">
      <el-row>
        <el-col :span="8">
          <el-button round type="primary" size="small" @click="addGate(true)"
          v-if="buttonAuth.includes('venueOperation:checkGate:add')"
            >添加</el-button
          >
          <el-button
            round
            type="primary"
            size="small"
            @click="addGate(true, true)"
            :disabled="multipleSelection.length !== 1 ? true : false"
            v-if="buttonAuth.includes('venueOperation:checkGate:update')"
            >修改</el-button
          >
          <el-button
            round
            type="primary"
            size="small"
            @click="openDelDialog(true)"
            :disabled="multipleSelection.length > 0 ? false : true"
            v-if="buttonAuth.includes('venueOperation:checkGate:delete')"
            >删除</el-button
          >
          <el-button
            round
            type="primary"
            size="small"
            @click="openConfirmDialog(true)"
            :disabled="multipleSelection.length !== 1 ? true : false"
            v-if="buttonAuth.includes('venueOperation:checkGate:open')"
            >开启</el-button
          >
        </el-col>
        <el-col :span="16" style="text-align: right">
          <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="form.pageNum"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="form.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="form.total"
            >
            </el-pagination>
          </div>
        </el-col>
      </el-row>
    </div>
    <add-dialog
      :show="addDialog.visible"
      @changeShow="addGate"
      :isEdit="addDialog.isEdit"
      ref="addDialog"
    >
    </add-dialog>
    <delete-dialog
      :show="deleteDialog.visible"
      @changeShow="openDelDialog"
      ref="deleteDialog"
      @sureDialog="delGate"
    >
    </delete-dialog>
    <confirm-dialog
      :show="confirmDialog.visible"
      text="确定开启闸门"
      @closeDialog="openConfirmDialog"
      ref="confirmDialog"
      @sureDialog="sureConfirmDialog"
    >
    </confirm-dialog>
  </div>
</template>

<script>
import mixin from "@/mixins/index";
import addDialog from "./dialog/addDialog.vue";
import deleteDialog from "./dialog/deleteDialog.vue";
import SearchClass from "./searchClass";
import apis from "@/apis";
import confirmDialog from "@/components/confirmDialogNew.vue";

export default {
  mixins: [mixin],
  components: {
    addDialog,
    deleteDialog,
    confirmDialog,
  },
  data() {
    return {
      form: new SearchClass("form"), // 实例化一个表单的变量
      addDialog: {
        visible: false,
        isEdit: false,
      },
      deleteDialog: {
        visible: false,
      },
      confirmDialog: {
        visible: false,
      },
      tableData: [],
      multipleSelection: [],
      ids: '',
    };
  },
  mounted() {},
  methods: {
    passageTypeFormatter(row, column, cellValue) {
      let string = ''
      if (cellValue === 'ENTER') {
        string = '进入'
      } else if (cellValue === 'OUT') {
        string = '离开'
      }
      return string
    },
    //   清空搜索框
    cleanForm() {
      this.form = new SearchClass("from");
      this.searchEvent();
    },
    openDelDialog(isVisible) {
      // this.searchEvent()
      this.deleteDialog.visible = isVisible;
    },
    // 开启闸门弹窗
    openConfirmDialog(isVisible) {
      this.confirmDialog.visible = isVisible;
    },
    // 确定开启闸门
    sureConfirmDialog() {
      this.$http.post(`${apis.gateManagerOpen}?deviceSn=${this.multipleSelection[0].deviceSn}`).then((res) => {
        if (res.data.code === 0) {
          this.openConfirmDialog(false)
        }
      })
    },
    searchData() {
      const formData = { ...this.form };
      console.log("formData:", formData);
      this.$http.post(apis.getGateList, formData).then((res) => {
        if (res.data.code === 0) {
          console.log("闸机", res.data.rows);
          this.tableData = res.data.rows;
          this.form.total = res.data.total
        }
      });
    },
    //点击添加/修改触发弹窗
    addGate(isVisible, type = false) {
      if (isVisible) {
        this.addDialog.isEdit = type;
        if (type) {
          if (this.multipleSelection.length > 0) {
            // console.log(this.multipleSelection);
            this.$refs["addDialog"].getDataQuery(this.multipleSelection);
          }
        }
      } else {
        this.searchEvent();
      }
      this.$nextTick(() => {
        this.addDialog.visible = isVisible;
      });
    },
    // 删除闸机
    delGate() {
      console.log(this.multipleSelection);
      this.multipleSelection.forEach((item, index) => {
        if (index === this.multipleSelection.length - 1) {
          this.ids += item.id
        } else {
          this.ids += `${item.id},`
        }
      });
      const ids = this.ids
      console.log(ids);
      this.$http.post(`${apis.deleteGate}?ids=${ids}`).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("删除成功！");
          this.openDelDialog(false)
          this.searchEvent()
          this.ids = ''
        }
      })
    },
    // 获取选中的数据
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
  activated() {
    this.searchEvent();
    // this.getVenuList();
    // this.getGateId();
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.operation {
  padding: 0 20px;
  margin: 20px 0;
}
</style>
